import React from 'react'
import Helmet from 'react-helmet'
import config from '../../../config/website'
import banner from '../../../static/images/banner.jpg'

import appleIcon from '../../../static/apple-touch-icon.png'
import icon32 from '../../../static/favicon-32x32.png'
import icon16 from '../../../static/favicon-16x16.png'
import safariSvg from '../../../static/safari-pinned-tab.svg'

export default ({title, description, keywords, image}) => {
    let ttl = (title ? title + ' | Дерево Желаний'  : config.siteTitle)
    let desc = (description ? description : config.siteDescription)
    let kwrds = (keywords ? keywords.toString() : config.siteKeywords)
    let img = (image ? image : banner)
    const domainName = 'https://www.desiretree.ru/';
    return (
        <Helmet>
        <html lang="ru"/>
        <meta charSet="utf-8"/>
        <title>{ttl}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content={kwrds}/> 
        <meta name="yandex-verification" content="99d5483ea459e305" />

        {/* FavIcons */}
        <link rel="apple-touch-icon" sizes="180x180" href={appleIcon}/>
        <link rel="icon" type="image/png" sizes="32x32" href={icon32}/>
        <link rel="icon" type="image/png" sizes="16x16" href={icon16}/>
        <link rel="mask-icon" href={safariSvg} color="#6b2807"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>



        {/* OpenGraph tags */}
        <meta property="og:title" content={ttl}/>
        <meta property="og:description" content={desc}/>
        <meta property="og:image" content={img}/>
        <meta property="og:url" content={domainName}/>

        {/* Twitter tags */}
        <meta name="twitter:title" content={ttl}/>
        <meta name="twitter:description" content={desc}/>
        <meta name="twitter:image" content={img}/>
        <meta name="twitter:card" content={domainName}/>

        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/>
        </Helmet>
    )

}