import React from 'react'
import Img from 'gatsby-image'
import './SocialMedia.scss'

function SocialMedia({socialMedia, extraClasses}) {
    return (
        <ul className={"social-media " + String(extraClasses)}>
        {
            socialMedia.map((item) => {
                return (
                    <li key={item.title} className="social-media--item">
                        <a href={item.description} className="social-media--link">
                            <Img className="social-media--icon" fluid={item.fluid} alt={item.title}/>
                        </a>
                    </li>
                )
            })
        }
    </ul>
    )
}

export default SocialMedia