import React from 'react'
import { navigate } from 'gatsby-link'
import MailIcon from '../../../static/svg/mail.svg'
import './PopupForm.scss'
// import '../IndexForm/IndexForm.scss'
import PhoneInput from 'react-phone-number-input'



function encode(data) {
    const formData = new FormData()
  
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
  
    return formData
}

export default function PopupForm({isOpened, closePopup}) {
    const [state, setState] = React.useState({})
    const [value, setValue] = React.useState()

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleAttachment = (e) => {
        setState({ ...state, [e.target.name]: e.target.files[0] })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        fetch('/', {
            method: 'POST',
            body: encode({
                'form-name': form.getAttribute('name'),
                'subject': 'Заявка с сайта Дерево Желаний',
                ...state,
            }),
        })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
    }

    let formClass;
    if (isOpened === true) {
        formClass = 'b-popup b-popup_opened'
    }
    else {
        formClass = 'b-popup b-popup_closed';
    }
    

    return (
        <div className={formClass} id="popup-form">
        <div className='b-popup-content'>
        <form 
            name="request-popup" 
            method="post" 
            action="/success" 
            className="form" 
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
        >
             {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
             <input type="hidden" name="form-name" value="request-popup" />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>

            <h4 className="b-popup--title">Оставить заявку</h4>

            <input type="hidden" id="subject" name="subject" value="Заявка с сайта Дерево Желаний" onChange={handleChange}/>
            <p className="form--group form--group_popup">
                <label htmlFor={'formName'} className="visually-hidden">Имя*</label>
                <input 
                    className="form--input" 
                    type={'text'} 
                    name={'p-name'}
                    onChange={handleChange} 
                    id={'formName'} 
                    placeholder='Имя*' 
                    required = {true}
                />
                
            </p>

            <p className="form--group form--group_popup">
                <label htmlFor={'p-formTel'} className="form--label">Телефон*</label>
                    <PhoneInput
                    placeholder="+7 (999) 999-99-99"
                    className="form--input form--input_tel" 
                    type={'tel'} 
                    name={'p-phone'} 
                    id={'p-formTel'} 
                    value={value}
                    onChange={setValue}
                    onInput={handleChange}
                    autoComplete
                    required
                    />
            </p>

            <p className="form--group form--group_popup">
                <label htmlFor={'formEmail'} className="visually-hidden">Email</label>
                <input 
                    name={'p-email'} 
                    id={'formEmail'} 
                    type={'email'} 
                    onChange={handleChange}
                    className="form--input" 
                    placeholder='Email'
                />
            </p>

            <p className="form--group form--group_popup">
                <label htmlFor={'formComment'} className="form--label">Комментарий</label>
                <textarea 
                    name={'p-comment'} 
                    className="form--textarea form--textarea_popup" 
                    id={'formComment'} 
                    onChange={handleChange}
                    rows='3'
                ></textarea>
            </p>
            
            <p className="form--group form--group_popup form--group_files">
                <label htmlFor="attachFiles" className="form--label">Прикрепить файлы</label>
                <input type="file" name="p-files"  id="attachFiles" onChange={handleAttachment}/>
                <p className="form--small-text">Если вы хотите отправить более 1 файла, пожалуйста, напишите письмо напрямую на почту <a href='mailto:dt@kronawood.ru'>dt@kronawood.ru</a></p>
            </p>

            <button type="submit" className="form--submit button">Связаться с нами <img className='form--submit-icon' src={MailIcon} alt="Конверт"/></button>
        </form>

        <button onClick={closePopup} className="b-popup--close" dangerouslySetInnerHTML={{__html: '&#10006'}}/>
            
        </div>
    </div>
    )
}
