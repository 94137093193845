import React from 'react'
import { Link } from 'gatsby'
import './HeaderNav.scss'

import {Nav, NavDropdown } from 'react-bootstrap'

function HeaderNav({navigation}) {
    function checkServicesActive() {
        if (typeof window !== `undefined`) {
            const url = window.location.href;
            if (url.includes('services')) {
                return 'header-nav--dropdown header-nav--dropdown_active'
            }
            else {
                return 'header-nav--dropdown '
            }
        }
        return 'header-nav--dropdown '
    }
    function dropdown(item) {
        return (
            <NavDropdown 
                title={item.title} 
                id={`nav-dropdown-${item.title}`} 
                className={checkServicesActive()}
            >
            {
                item.subPages.map(subPage => {
                    return (
                        <Link
                            key={subPage.slug}
                            to={`/${item.slug}/${subPage.slug}`}
                            className='dropdown-item'
                        >
                        {subPage.title}
                        </Link>
                    )
                })
            }
            </NavDropdown>
        )
    }

    function simpleLink(item) {
        return (
            <Link
                to = {`/${item.slug}`}
                className='header-nav--link nav-link' 
                activeClassName='header-nav--link_active'

            >
            {item.title}
            </Link>
        )
    }
    return (
        <nav className="header-nav">
            <Nav as={'ul'} className='header-nav--ul'>
                {
                    navigation.map(page => {
                        return (
                            <Nav.Item key={page.slug} as={'li'} className='header-nav--item'>
                            {
                                page.subPages ? dropdown(page) : simpleLink(page)
                            }
                            </Nav.Item>
                        )

                    })
                }
            </Nav>
            {/* <Nav as={'ul'} className='header-nav--ul'>
                <Nav.Item as={'li'} className='header-nav--item'>
                    <NavDropdown title={mainNavTitle[0]} id="nav-dropdown" className={checkServicesActive()}>
                        <Link className='dropdown-item' to={'/' + mainNavSlug[0] + '/' + subNavSlug[0]}>{subNavTitle[0]}</Link>
                        <Link className='dropdown-item' to={'/' + mainNavSlug[0] + '/' + subNavSlug[1]}>{subNavTitle[1]}</Link>
                        <Link className='dropdown-item' to={'/' + mainNavSlug[0] + '/' + subNavSlug[2]}>{subNavTitle[2]}</Link>
                        <Link className='dropdown-item' to={'/' + mainNavSlug[0] + '/' + subNavSlug[3]}>{subNavTitle[3]}</Link>
                        <Link className='dropdown-item' to={'/' + mainNavSlug[0] + '/' + subNavSlug[4]}>{subNavTitle[4]}</Link>
                        {
                            navigation.subPages.map(page => {
                                <Link
                                    className='dropdown-item'
                                    to={`/${navigation.slug}/${page.slug}`}
                                >
                                    {page.title}
                                </Link>
                            })
                        }
                    </NavDropdown>
                </Nav.Item>
                {/* <Nav.Item  as={'li'} className='header-nav--item'>
                    <Link className='header-nav--link nav-link' activeClassName='header-nav--link_active' to={'/' + mainNavSlug[1]}>{mainNavTitle[1]}</Link>
                </Nav.Item> 
                <Nav.Item  as={'li'} className='header-nav--item'>
                    <Link className='header-nav--link nav-link' activeClassName='header-nav--link_active' to={'/' + mainNavSlug[2]}>{mainNavTitle[2]}</Link>
                </Nav.Item>
                <Nav.Item  as={'li'} className='header-nav--item'>
                    <Link className='header-nav--link nav-link' activeClassName='header-nav--link_active' to={'/' + mainNavSlug[3]}>{mainNavTitle[3]}</Link>
                </Nav.Item>
                <Nav.Item  as={'li'} className='header-nav--item'>
                    <Link className='header-nav--link nav-link' activeClassName='header-nav--link_active' to={'/' + mainNavSlug[4]}>{mainNavTitle[4]}</Link>
                </Nav.Item>
            </Nav> */}


        </nav>
    )
}

export default HeaderNav