import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Seo from '../Seo/Seo'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import PopupForm from '../PopupForm/PopupForm'
import {Animated} from "react-animated-css";

import './Layout.scss'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'react-image-lightbox/style.css';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import "animate.css/animate.min.css";
import 'react-phone-number-input/style.css'
import '../../styles/breadcrumb.scss'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isPopupOpened: false};
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  openPopup() {
    this.setState({isPopupOpened: true});
  }
  closePopup() {
    this.setState({isPopupOpened: false});
  }
  
  
  render() {
    const {location, children} = this.props
    return (
      <div className='layout'>
        <Seo/>
        <div id='top'></div>
        <Header location={location} openForm={this.openPopup}/>
        
        <Animated>
          <main>
              {children}
          </main>
          <div className="container button-up--wrapper">
              <AnchorLink offset='1000'  href="#top" id="buttonUp" className="button-up"></AnchorLink>
          </div>
        </Animated>

        <PopupForm isOpened={this.state.isPopupOpened} closePopup={this.closePopup}/>
        <Footer/>
      </div>
    )
  }
}

export default Layout
