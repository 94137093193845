import React from 'react'
import './Footer.scss'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {Container, Row, Col} from 'react-bootstrap'
import Img from 'gatsby-image'
import normalizePhone from '../../functions/phone-normalizer'

function Footer() {
    const data = useStaticQuery(graphql`
    query footerQuery {
        allContentfulServicePage {
            nodes {
            title
            slug
            }
        }
        contentfulAboutSection(slug: {eq: "vacancies"}) {
            title
            slug
        }
        contentfulSiteNavigation(slug: {eq: "contacts"}) {
            title
            slug
        }
        contentfulCompanyContacts {
            address
            mail
            phones 
            socialMediaIcons {
                fluid(maxWidth: 30) {
                    ...GatsbyContentfulFluid
                }
                title
                description
              }
        }

        allContentfulVacancy {
            nodes {
              id
            }
          }
    }
  `)
   
  const companyTel = data.contentfulCompanyContacts.phones[0];
  const companyEmail = data.contentfulCompanyContacts.mail;
  const companyAddress = data.contentfulCompanyContacts.address;

  const vacanciesAmount = data.allContentfulVacancy.nodes.length;
    return (
        <footer className="footer--bg">
            <Container className="footer" fluid="xl">
                <Row>
                    <Col as={'nav'} className="footer--col"  sm={4} xs={6} lg={3}>
                        <ul className="footer--nav">
                            {
                                data.allContentfulServicePage.nodes.map(service => {
                                    return (
                                        <li className="footer--nav-item">
                                            <Link
                                                to={`/services/${service.slug}`}
                                                className='footer--link'
                                                activeClassName='footer--link_active'
                                                 
                                            >
                                            {service.title}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                    <Col className="footer--col" sm={4} xs={6} lg={2}>
                        <ul className="footer--nav">
                            <li className="footer--nav-item">
                                <a className="footer--link" href="https://www.kronawood.ru/">Купить пиломатериалы</a>
                            </li>
                            <li className="footer--nav-item">
                                <Link 
                                    to={`/about/${data.contentfulAboutSection.slug}`}
                                    className="footer--link footer--link_vacancies" 
                                    activeClassName='footer--link_active'  
                                    partiallyActive={true}>
                                    {data.contentfulAboutSection.title}
                                    <span className="footer--vacancies-amount">{vacanciesAmount}</span></Link>
                            </li>
                            <li className="footer--nav-item">
                                <Link 
                                    to={`/${data.contentfulSiteNavigation.slug}`} 
                                    className="footer--link" 
                                    activeClassName='footer--link_active'
                                >
                                {data.contentfulSiteNavigation.title}
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col className="footer--col" sm={4} xs={6} lg={4}>
                        <div className="footer--column-wrapper">
                        <div className="footer--row footer--row_phones">
                            <div className="footer--col-inner">
                                <a className="footer--link footer--company-contact" href={"tel:" + companyTel}>{normalizePhone(companyTel)}</a>
                            </div>
                            <div className="footer--col-inner">
                                <a  className="footer--link footer--company-contact" href={"mailto:" + companyEmail}>{companyEmail}</a>
                            </div>
                        </div>

                        <div className="footer--row">
                            <p className="footer--address">{companyAddress}</p>
                        </div>
                        </div>
                    </Col>
                    <Col className="footer--col" sm={12} xs={6} lg={3}>
                        <ul className="footer--social-media social-media">
                            {
                                data.contentfulCompanyContacts.socialMediaIcons.map((item) => {
                                    return (
                                        <li key={item.title} className="social-media--item">
                                            <a href={item.description} className="social-media--link">
                                                <Img className="social-media--icon" fluid={item.fluid} alt={item.title}/>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                        <span className="footer--privacy">
                            <Link className="footer--link" activeClassName='footer--link_active' to='/privacy'>Политика конфиденциальности</Link>
                        </span>
                        <span className="footer--copyright">
                            2000 - 2021г © Дерево Желаний
                        </span>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer