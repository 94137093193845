module.exports = {
    siteTitle: 'Столярное производство в СПб | Дерево Желаний', // Navigation and Site Title
    siteTitleAlt: 'Столярное производство в СПб | Дерево Желаний', // Alternative Site title for SEO
    siteTitleShort: 'Дерево Желаний', // short_name for manifest
    siteUrl: process.env.ROOT_URL || 'https://desiretree.ru', // Domain of your site. No trailing slash!
    siteKeywords: 'столярное производство спб, лестница на заказ, реставрация КГИОП, отделка деревянных интерьеров',
    lang: 'ru', // Language Tag on <html> element
    pathPrefix: '/',
    // siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
    siteDescription:
      'Производство и реставрация деревянных изделий - лестниц, интерьеров, дверей, окон в Санкт-Петербурге',
    minibio: `
        Производство и реставрация деревянных изделий - лестниц, интерьеров, дверей, окон в Санкт-Петербурге
    `,
    author: 'Timur SaltyRain Garipov', // Author for schemaORGJSONLD

  }