import React from 'react'
import './Hamburger.scss'

function Hamburger({clickHandler}) {
    return (
        <button onClick={clickHandler}
        className="hamburger" type="button"
        aria-label="Menu" aria-controls="navigation" aria-expanded="true/false">
        <span className="hamburger-box">
            <span className="hamburger-inner"></span>
        </span>
        </button>

    )
}

export default Hamburger
