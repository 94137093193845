import React from 'react'
import { Link } from 'gatsby'
import './Button.scss'

function Button({as, text, extraClasses, linkTo, handler}) {
    if (extraClasses === undefined)
        extraClasses = '';

    if (as === 'link'){
        return (
            <Link className={"button " + extraClasses} to={'/' + linkTo}>{text}</Link>
        )
    }
    else if (as ==='external') {
        return (
            <a href={linkTo} className={"button " + extraClasses}>{text}</a>
        )
    }
    else {
        return (
            <button onClick={handler} className={"button " + extraClasses} type='button'>{text}</button>
        )
    }
}

export default Button
