import React from 'react'
import { Link } from 'gatsby'
import './MobileNav.scss'

function MobileNav({navigation, extraClasses}) {
    return (
        <nav className={"mobile-nav " + extraClasses}>
            <ul className='mobile-nav--list'>
            {
                navigation.map(page => {
                    return (
                        <li key={page.slug} className='mobile-nav--item'>
                        <Link
                            to={`/${page.slug}`}
                            className='mobile-nav--link'
                        >
                            {page.title}
                        </Link>
                        {
                            page.subPages ?
                                <ul className='mobile-nav--sublist'>
                                    {
                                        page.subPages.map(subPage => {
                                            return (
                                                <li key={subPage.slug} className='mobile-nav--subitem'>
                                                    <Link
                                                        to={`/${page.slug}/${subPage.slug}`}
                                                        className='mobile-nav--link'
                                                        activeClassName='mobile-nav--link_active'
                                                    >
                                                        {subPage.title}
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul> :
                                ''
                            
                        }
                    </li>
                    )
                })
            }
            </ul>
        </nav>
    )
}

export default MobileNav