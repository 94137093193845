import React from 'react'
import './KronaLogo.scss'
import KronaLogoSrc from '../../../static/images/krona-logo.png'

function KronaLogo() {
    return(
        <a href="https://www.kronawood.ru/"  className="krona-logo">
        <span className="krona-logo--text">Группа компаний</span>
        <img className="krona-logo--img" src={KronaLogoSrc} alt='Перейти на сайт kronawood.ru'/>
        
        </a>
    )
}


export default KronaLogo